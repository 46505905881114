import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Popup.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Popup({
  children,
  visible,
  handleClosePopup,
}: {
  children: React.ReactNode;
  visible?: boolean;
  handleClosePopup?: () => void;
}) {
  // use visible as the default state if it is provided, otherwise use false
  const [isVisible, setIsVisible] = useState(visible ?? false);

  // if visible is provided, update the state when it changes
  useEffect(() => {
    if (visible !== undefined) {
      setIsVisible(visible);
    }
  }, [visible]);

  // Use AnimatePresence to handle exit animations
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="popup"
          onClick={handleClosePopup}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="popup-content"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            
            {children}
            <div onClick={handleClosePopup}
              className='close-button'
            >
              <KeyboardArrowDownIcon />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
