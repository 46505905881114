import { AnimatePresence, motion } from "framer-motion"
import { Pack } from "./Interfaces"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function Samples({allTracks}: {allTracks?: Pack}) {
    return (
        <div className="samples">
            {allTracks?.tracks.map((track) => (
                <SampleView track={track} key={track.id}/>
            ))}
        </div>
    )
}

function SampleView({track}: {track?: any}) {

    // convert track duration to minutes and seconds and display as a string in the time format mm:ss
    // ensure seconds always has two digits
    const minutes = Math.floor(track?.duration / 60);
    const seconds = Math.floor(track?.duration % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    const time = minutes + ":" + seconds;


    return (
        <div className="sampleview">
            <div>
                <h4>{track?.name}</h4>
                <h5 style={{color: "grey"}}>{track?.producers.join(", ")}</h5>
            </div>
            
            <div className="sampleview-play">
                {time}
                <PlayArrowIcon sx={{color: 'lightgray'}}/>
            </div>
        </div>
    )
}