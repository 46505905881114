import './Styles.css'
import logo from '../../assets/images/logo192.png';
import { useLocation, useNavigate } from 'react-router-dom';

export default function MainMenu() {

    // Location is the pathname of the slug with the first letter capitalized
    const location = useLocation().pathname.slice(1).charAt(0).toUpperCase() + useLocation().pathname.slice(2);
    const navigate = useNavigate();

    return (
        <div className="main-menu">
            <img src={logo} alt="logo" className="menu-logo" onClick={() => navigate('/')}/>
            <p style={{fontSize: '0.8rem', color: 'lightgray'}}>{useLocation().pathname !== '/' ? location : 'Home'}</p>
        </div>
    );
}