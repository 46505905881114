import { AnimatePresence, motion } from "framer-motion"
import './Styles.css'
import { Pack } from "./Interfaces"
import { useState } from 'react'
import PackDetails from "./PackDetails"

export default function Packs({allPacks}: {allPacks?: Pack[]}) {

    const [selectedPack, setSelectedPack] = useState<Pack | null>(null)

    return (
        <div className="packs">
            {allPacks?.map((pack: Pack) => {
                return <PackView pack={pack} setSelectedPack={setSelectedPack}/>
            })}
            {selectedPack ? <PackDetails pack={selectedPack} setSelectedPack={setSelectedPack} /> : null}
        </div>
    )
}

function PackView({pack, setSelectedPack}: {pack?: Pack, setSelectedPack?: any}) {

    return (
        <div style={{ background: `linear-gradient(45deg, #1a1a1a 0%, rgba(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},1) 100%)` }}
            className="packview"
            onClick={() => {
                setSelectedPack(pack)
                console.log(pack)
            }}
        >
            <h3>{pack?.name}</h3>
            <h5 style={{color: "gray"}}>14 Tracks</h5>
        </div>
    )
}