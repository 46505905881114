import MainMenu from "../components/menus/MainMenu";
import FooterMenu from "../components/menus/FooterMenu";
import { AnimatePresence, motion } from "framer-motion";

export default function Account() {
    return (
        <AnimatePresence>
            <MainMenu />
            <motion.div className="page"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                Account page here
            </motion.div>
            <FooterMenu />
        </AnimatePresence>
    );
}