import './Styles.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Popup from '../popups/Popup';

// Icons
import AlbumIcon from '@mui/icons-material/Album';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import AttributionIcon from '@mui/icons-material/Attribution';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export default function FooterMenu() {

    const navigate = useNavigate();
    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <div className="footer-menu">
            <div className="footer-icon-container"
                onClick={() => navigate('/')}
            >
                <AlbumIcon sx={{fontSize: '2rem'}}/>
            </div>
            <div className="footer-icon-container"
                onClick={() => setMenuVisible(true)}
            >
                <MenuIcon sx={{fontSize: '2rem'}}/>
            </div>
            <Popup visible={menuVisible} handleClosePopup={() => setMenuVisible(false)}>
                <div className="menu-popup-item"
                    onClick={() => navigate('/account')}
                >
                    Account <PersonIcon sx={{fontSize: '1.25rem'}}/>
                </div>
                <div className="menu-popup-item"
                    onClick={() => navigate('/usage')}
                >
                    Usage Rights <AttributionIcon sx={{fontSize: '1.25rem'}}/>
                </div>
                <a href='mailto:john@kearneyjohn.com' className="menu-popup-item">
                    Contact <AlternateEmailIcon sx={{fontSize: '1.25rem'}}/>
                </a>
            </Popup>
        </div>
    );
}