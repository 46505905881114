import { AnimatePresence, motion } from "framer-motion"
import { Pack } from "./Interfaces"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';

export default function PackDetails({pack, setSelectedPack}: {pack?: Pack | null, setSelectedPack?: any}) {

    if (!pack) return (null);

    return (
        <AnimatePresence mode='wait'>
            <motion.div className="pack-details"
                initial={{ opacity: 0}}
                animate={{ opacity: 1}}
                exit={{ opacity: 0 }}
                onClick={() => {
                    setSelectedPack(null)
                }}
            >
                <div className="close-pack-details-btn"
                    onClick={() => {
                        setSelectedPack(null)
                    }}
                >
                    <ArrowBackIcon />
                </div>
                
                <div className="pack-details-content"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <div className="pack-details-gradient"
                        style={{ background: `linear-gradient(45deg, #1a1a1a 0%, rgba(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},1) 100%)` }}
                    />
                
                    <h1>{pack?.name}</h1>
                    <h3>{pack?.genre}</h3>
                    <p style={{color: 'gray', textAlign: 'center'}}>{pack?.description}</p>
                    <h3 style={{color: 'gray'}}><DownloadIcon /></h3>
                    <div className="pack-details-tracks">
                        {pack?.tracks.map((track) => {
                            return (
                                <div className="pack-details-track">
                                    <div className="pack-details-track-inner">
                                        <p>{track.name}</p>
                                        <p style={{fontSize: '0.8rem', color: 'gray'}}>{track.producers[0]}</p>
                                    </div>
                                    <PlayArrowIcon />
                                </div>
                            )
                        })}
                    </div>

                </div>

                
            </motion.div>
        </AnimatePresence>
    )
}