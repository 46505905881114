import './Styles.css'
import { AnimatePresence, motion } from "framer-motion";
import { useState } from 'react';

// Components
import MainMenu from "../components/menus/MainMenu";
import FooterMenu from "../components/menus/FooterMenu";
import Packs from '../components/catalog/Packs';
import Samples from '../components/catalog/Samples';
import Beats from '../components/catalog/Beats';
import { Pack, Track } from '../components/catalog/Interfaces';

export default function Home() {

    const [activeTab, setActiveTab] = useState(1);

    const trackOne: Track = {
        id: "1",
        name: "Track One (Example)",
        bpm: 120,
        duration: 137,
        trackURL: "https://samplelib.com/lib/preview/mp3/sample-12s.mp3",
        producers: ["Kearney"],
        type: "sample",
        genre: "granular"
    }

    const trackTwo: Track = {
        id: "2",
        name: "Track Two (Example)",
        bpm: 120,
        duration: 120,
        trackURL: "https://samplelib.com/lib/preview/mp3/sample-12s.mp3",
        producers: ["Kearney", "Maasho"],
        type: "sample",
        genre: "Granular"
    }

    const pack: Pack = {
        id: "1",
        name: "September 2023",
        description: "A collection of samples from September 2023. This is dummy data for testing.",
        tracks: [trackOne, trackTwo, trackTwo, trackTwo, trackTwo, trackTwo, trackTwo, trackTwo, trackTwo, trackOne],
        genre: "Trap",
        imageURL: "https://picsum.photos/200",
        type: "sample"
    }

    const allPacks = [pack, pack, pack, pack, pack, pack, pack]


    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <MainMenu />
                <div className='page'>
                    <div className='home-tabs'>
                        <div className={activeTab === 0 ? 'home-tab selected' : 'home-tab'} onClick={() => setActiveTab(0)}>
                            Samples
                        </div>
                        <div className={activeTab === 1 ? 'home-tab selected' : 'home-tab'} onClick={() => setActiveTab(1)}>
                            Packs
                        </div>
                        <div className={activeTab === 2 ? 'home-tab selected' : 'home-tab'} onClick={() => setActiveTab(2)}>
                            Beats
                        </div>
                    </div>
                    
                    {activeTab === 0 && <Samples allTracks={pack} />}
                    {activeTab === 1 && <Packs allPacks={allPacks}/>}
                    {activeTab === 2 && <Samples allTracks={pack} />}

                </div>
                <FooterMenu />
            </motion.div>
        </AnimatePresence>
    )
}